import * as THREE from "three";
import EventDispatcher from "../common/EventDispatcher";

export default class Camera extends THREE.PerspectiveCamera {
  zLerp = new THREE.Vector3();
  yLerp = new THREE.Vector3();
  xLerp = new THREE.Vector3();
  gameStarted = true;
  constructor(fov, ratio, near, far) {
    super(fov, ratio, near, far);
  }
  init() {
    this.events = EventDispatcher.getObj();
    this.events.addEventListener("CAMERA_EVENTS", this.onEvents.bind(this));
    this.events.addEventListener("GAMESCENE_EVENTS", this.onEvents.bind(this));
    this.events.addEventListener("PLAYER_EVENTS", this.onEvents.bind(this));

    this.position.set(0, 0.1, -0.5);
    // this.position.set(0, 0.011, 0.043);
    // this.yLerp = new THREE.Vector3(0, 0.1, 0);
    // this.zLerp = new THREE.Vector3(0, 0.07, 0.001);
    // this.rotationVal = new THREE.Vector3(0, 0, 0);
    // this.xLerp = this.position.clone();

    // this.lookAt(new THREE.Vector3(0, 0.011, 0));
  }
  onEvents(data) {
    switch (data["message"]["event_type"]) {
      case "resize":
        this.onResize(data["message"]["data"]);
        break;
      case "update":
        this.update();
        break;
    }
  }
  onResize(data) {
    this.aspect = data.aspect;
    this.updateProjectionMatrix();
  }
  update() {
    // this.position.z =-1.8;//-=0.001;
    // this.position.z -= 0.003;
  }
}
