import * as THREE from "three";
import { Global } from "../common/global";
import EventDispatcher from "../common/EventDispatcher";
import gsap from "gsap";

export default class GameScene {
  constructor(scene, camera, gui) {
    this.scene = scene;
    this.camera = camera;
    this.gui = gui;
  }
  init() {
    this.xVal = 0;
    this.yVal = 0;
    this.backLineCount = 0;
    this._currRoad = null;
    this.calledRoadShift = false;
    this.oldCamPos = 0;
    this.roadCount = 0;
    this.leftLightArray = [];
    this.rightLightArray = [];
    this.LeftLastLight = null;
    this.resetLeftLightBOOL = false;
    this.resetRightLightBOOL = false;
    this.RightLastLight = null;

    this.LeftTreeArray = [];
    this.lastLeftTree = null;
    this.resetLeftTreeBOOL = false;
    this.resetRightTreeBOOL = false;
    this.RightTreeArray = [];
    this.lastRightTree = null;

    this.LeftBuildingArr = [];
    this.RightBuildingArr = [];
    this.lastLeftBuilding = null;
    this.lastRightBuilding = null;
    this.resetLeftBuildingBOOL = false;
    this.resetRightBuildingBOOL = false;

    this.prevRanNum = 0;
    this.blinkLight = false;
    this.blinkLight2 = false;

    this.CloudArray = [];

    this.allElementMovementSpeed = 0.001;

    this.events = EventDispatcher.getObj();
    this.events.addEventListener("GAMESCENE_EVENTS", this.onEvents.bind(this));
    this.events.addEventListener("PLAYER_EVENTS", this.onEvents.bind(this));
  }
  onEvents(data) {
    switch (data["message"]["event_type"]) {
      case "add_BasketBall_set":
        this.addBasketBallSet();
        break;
      case "update":
        this.update();
        break;
    }
  }
  // update() {}

  addBasketBallSet() {
    this.referenceWith = 0.25;
    this.referenceHeight = 1;
    this.plane0 = new THREE.PlaneGeometry(
      this.referenceWith,
      this.referenceHeight
    );
    this.plane1 = new THREE.PlaneGeometry(
      this.referenceWith,
      this.referenceHeight
    );
    this.plane2 = new THREE.PlaneGeometry(
      this.referenceWith,
      this.referenceHeight
    );

    this.bgTexture = Global.assets["textures"]["road"]["asset"];
    this.bgTexture2 = Global.assets["textures"]["road2"]["asset"];

    this._BgPlane = new THREE.PlaneGeometry(7, 4);
    if (Global.country === "USA") {
      this.SkyColorCode = 0xe5fffc;
    } else if (Global.country === "JAPAN") {
      this.SkyColorCode = 0xfceacf;
    } else if (Global.country === "CHINA") {
      this.SkyColorCode = 0xf0feff;
    } else if (Global.country === "KOREA") {
      this.SkyColorCode = 0xd7f1fe;
    }
    this._BgMaterial = new THREE.MeshBasicMaterial({
      color: this.SkyColorCode,
      side: THREE.DoubleSide,
      polygonOffset: true,
      polygonOffsetFactor: -0.1,
    });

    if (
      Global.country === "USA" ||
      Global.country === "CHINA" ||
      Global.country === "KOREA"
    ) {
      this.GroundColorCode = 0x9bd9d2;
    } else if (Global.country === "JAPAN") {
      this.GroundColorCode = 0xefede3;
    } /* else if (Global.country === "CHINA") {
      this.GroundColorCode = 0x9bd9d2;
    } else if (Global.country === "KOREA") {
      this.GroundColorCode = 0x9bd9d2;
    } */
    this._GroundPlane = new THREE.PlaneGeometry(1, 1);
    this._GroundMaterial = new THREE.MeshBasicMaterial({
      color: this.GroundColorCode,
      side: THREE.DoubleSide,
    });
    this._GreenGround = new THREE.Mesh(this._BgPlane, this._GroundMaterial);
    this.scene.add(this._GreenGround);
    this._GreenGround.rotation.x = -Math.PI / 2;
    this._GreenGround.position.y = -0.01;

    /* const colorController = this.gui.addColor({ color: "#80dfff" }, "color");
    colorController.onChange(
      function (color) {
        this._planeBlueMaterial.color.set(color);
        // You can do something with the updated color here.
        console.log("Updated color: " + color);
      }.bind(this)
    ); */

    this._BgColor = new THREE.Mesh(this._BgPlane, this._BgMaterial);
    this.scene.add(this._BgColor);
    this._BgColor.position.z = 2;

    this._BgPlane2 = new THREE.PlaneGeometry(2, 2);
    this._BgColor2 = new THREE.Mesh(this._BgPlane2, this._BgMaterial);
    this.scene.add(this._BgColor2);
    this._BgColor2.position.z = 0.81;

    //Adding Mountains
    this._MountainPlane = new THREE.PlaneGeometry(2 * 0.4, 1 * 0.4);

    this._MountainTextureLEFT =
      Global.assets["textures"][`${Global.country}_LeftMountain`]["asset"];
    this._MountainTextureRIGHT =
      Global.assets["textures"][`${Global.country}_RightMountain`]["asset"];

    this._MountainTextureLEFT.minFilter = THREE.NearestFilter;
    this._MountainTextureLEFT.magFilter = THREE.NearestFilter;

    this.leftMountainMaterial = new THREE.MeshBasicMaterial({
      map: this._MountainTextureLEFT,
      // aoMap: this._MountainTextureLEFT,
      side: THREE.DoubleSide,
      transparent: true,
    });
    this.RightMountainMaterial = new THREE.MeshBasicMaterial({
      map: this._MountainTextureRIGHT,
      side: THREE.DoubleSide,
      transparent: true,
    });

    this._MountainTextureRIGHT.minFilter = THREE.NearestFilter;
    this._MountainTextureRIGHT.magFilter = THREE.NearestFilter;
    this._LeftMountain = new THREE.Mesh(
      this._MountainPlane,
      this.leftMountainMaterial
    );
    this._RightMountain = new THREE.Mesh(
      this._MountainPlane,
      this.RightMountainMaterial
    );
    this.scene.add(this._LeftMountain);
    this._LeftMountain.position.z = 0.6;
    this._LeftMountain.position.y = 0.1;
    this._LeftMountain.scale.set(-1, 1, 1);
    this._LeftMountain.position.x = 0.58;

    this.scene.add(this._RightMountain);
    this._RightMountain.position.z = 0.6;
    this._RightMountain.position.y = 0.1;
    // this._RightMountain.scale.set(-1, 1, 1);
    this._RightMountain.position.x = -0.58;

    if (Global.country === "JAPAN") {
      this.sunPlane = new THREE.PlaneGeometry(0.07, 0.07);
      this.sunTexture = Global.assets["textures"]["Sun"]["asset"];
      this._SunMaterial = new THREE.MeshBasicMaterial({
        map: this.sunTexture,
        side: THREE.DoubleSide,
        transparent: true,
        // polygonOffset: true,
        // polygonOffsetFactor: -0.1,
      });
      this.JapanSun = new THREE.Mesh(this.sunPlane, this._SunMaterial);
      this.scene.add(this.JapanSun);
      this.JapanSun.position.z = 0.3;
      this.JapanSun.position.y = 0.175;
      this.JapanSun.position.x = 0.11;
    }

    this._CloudPlane = new THREE.PlaneGeometry(2.97 * 0.065, 1 * 0.065);
    this._CloudTexture = Global.assets["textures"]["Cloud"]["asset"];
    this._CloudTexture.minFilter = THREE.NearestFilter;
    this._CloudTexture.magFilter = THREE.NearestFilter;
    this._CloudMaterial = new THREE.MeshBasicMaterial({
      map: this._CloudTexture,
      side: THREE.DoubleSide,
      transparent: true,
      polygonOffset: true,
      polygonOffsetFactor: -0.1,
    });

    // this.bgTexture.wrapS = THREE.RepeatWrapping;
    // this.bgTexture.wrapT = THREE.RepeatWrapping;
    // this.bgTexture.repeat.set(10, 10); // Increase or decrease as needed

    // // // Adjust the texture offset
    // this.bgTexture.offset.set(0.25, 0.25); // Shift the texture as needed
    this.material = new THREE.MeshBasicMaterial({
      map: this.bgTexture,
      aoMap: this.bgTexture,
      aoMapIntensity: 0.3,
      side: THREE.DoubleSide,
    });
    this.material2 = new THREE.MeshBasicMaterial({
      map: this.bgTexture2,
      aoMap: this.bgTexture2,
      aoMapIntensity: 0.3,
      side: THREE.DoubleSide,
    });
    this.road3 = new THREE.Mesh(this.plane0, this.material);
    this.road3.name = "Road3";
    this.road1 = new THREE.Mesh(this.plane1, this.material2);
    this.road1.name = "Road1";
    this.road2 = new THREE.Mesh(this.plane2, this.material);
    this.road2.name = "Road2";

    this.road3.rotation.x = -Math.PI / 2;
    this.road1.rotation.x = -Math.PI / 2;
    this.road2.rotation.x = -Math.PI / 2;
    // this.road2.position.y = 1;
    this.road3.position.z = 2;
    this.road2.position.z = 1;
    //console.log(this.road2.geometry.parameters, "road");

    this.scene.add(this.road3);
    // //console.log(this.road3.position.z - this.camera.position.z, "ww");
    this.scene.add(this.road1);
    this.scene.add(this.road2);

    this.roadArray = [this.road1, this.road2, this.road3];
    this._currRoad = this.roadArray[0];
    //console.log(this._currRoad, "CurrRoad");

    this._bigBuildingPlane = new THREE.PlaneGeometry(2.15 * 0.2, 1 * 0.2);
    this._bigBuildingTexture =
      Global.assets["textures"][`${Global.country}_bigbuilding`]["asset"];

    this._bigBuildingTexture.minFilter = THREE.NearestFilter;
    this._bigBuildingTexture.magFilter = THREE.NearestFilter;

    this._BigBuildingMaterial = new THREE.MeshBasicMaterial({
      map: this._bigBuildingTexture,
      side: THREE.DoubleSide,
      transparent: true,
      polygonOffset: true,
      polygonOffsetFactor: -0.1,
    });
    this._BigBuilding = new THREE.Mesh(
      this._bigBuildingPlane,
      this._BigBuildingMaterial
    );
    this.scene.add(this._BigBuilding);
    this._BigBuilding.position.z = 0.8;
    if (Global.country === "KOREA" || Global.country === "JAPAN") {
      this._BigBuilding.position.x = -0.05;
    }
    this._BigBuilding.position.y = 0.1;
    this._BigBuilding.rotation.y = -Math.PI;

    this.camera.lookAt(new THREE.Vector3(0, 0.011, 0));
    // this.BasketBallGround = Global.assets["glbs"]["basketBallGround"]["asset"];
    // this.scene.add(this.BasketBallGround.scene);
    // this.animate();
    this._roadLightPlane = new THREE.PlaneGeometry(0.005, 0.005);
    this._roadLightTexture1 = Global.assets["textures"]["road_light1"]["asset"];
    this._roadLightTexture2 = Global.assets["textures"]["road_light2"]["asset"];

    //Adding Tree
    this._treePlane = new THREE.PlaneGeometry(0.62 * 0.05, 1 * 0.05);
    this._treeTexture = Global.assets["textures"]["tree"]["asset"];
    this._treeTexture2 = Global.assets["textures"]["tree2"]["asset"];
    this._treeTexture3 = Global.assets["textures"]["tree3"]["asset"];

    this._treeMaterial = new THREE.MeshBasicMaterial({
      map: this._treeTexture,
      side: THREE.DoubleSide,
      transparent: true,
    });
    this._treeMaterial2 = new THREE.MeshBasicMaterial({
      map: this._treeTexture2,
      side: THREE.DoubleSide,
      transparent: true,
    });
    this._treeMaterial3 = new THREE.MeshBasicMaterial({
      map: this._treeTexture3,
      side: THREE.DoubleSide,
      transparent: true,
    });

    //Adding Side Building
    this._BuildingPlane1 = new THREE.PlaneGeometry(0.68 * 0.13, 1 * 0.13);

    if (Global.country === "JAPAN" || Global.country === "KOREA") {
      this._BuildingTexture1 =
        Global.assets["textures"][`${Global.country}_building1`]["asset"];
      this._BuildingTexture2 =
        Global.assets["textures"][`${Global.country}_building2`]["asset"];
      this._BuildingTexture3 =
        Global.assets["textures"][`${Global.country}_building3`]["asset"];
      this._BuildingTexture4 =
        Global.assets["textures"][`${Global.country}_building4`]["asset"];
    } else {
      this._BuildingTexture1 =
        Global.assets["textures"][`${Global.country}_building1`]["asset"];
      this._BuildingTexture2 =
        Global.assets["textures"][`${Global.country}_building2`]["asset"];
      this._BuildingTexture3 =
        Global.assets["textures"][`${Global.country}_building3`]["asset"];
      this._BuildingTexture4 =
        Global.assets["textures"][`${Global.country}_building4`]["asset"];
      this._BuildingTexture5 =
        Global.assets["textures"][`${Global.country}_building5`]["asset"];
      this._BuildingTexture6 =
        Global.assets["textures"][`${Global.country}_building6`]["asset"];
    }

    this._BuildingMaterial1 = new THREE.MeshBasicMaterial({
      map: this._BuildingTexture1,
      side: THREE.DoubleSide,
      transparent: true,
      polygonOffset: true,
      polygonOffsetFactor: -0.1,
    });
    this._BuildingMaterial2 = new THREE.MeshBasicMaterial({
      map: this._BuildingTexture2,
      side: THREE.DoubleSide,
      transparent: true,
      polygonOffset: true,
      polygonOffsetFactor: -0.1,
    });
    this._BuildingMaterial3 = new THREE.MeshBasicMaterial({
      map: this._BuildingTexture3,
      side: THREE.DoubleSide,
      transparent: true,
      polygonOffset: true,
      polygonOffsetFactor: -0.1,
    });
    this._BuildingMaterial4 = new THREE.MeshBasicMaterial({
      map: this._BuildingTexture4,
      side: THREE.DoubleSide,
      transparent: true,
      polygonOffset: true,
      polygonOffsetFactor: -0.1,
    });
    this._BuildingMaterial5 = new THREE.MeshBasicMaterial({
      map: this._BuildingTexture5,
      side: THREE.DoubleSide,
      transparent: true,
      polygonOffset: true,
      polygonOffsetFactor: -0.1,
    });
    this._BuildingMaterial6 = new THREE.MeshBasicMaterial({
      map: this._BuildingTexture6,
      side: THREE.DoubleSide,
      transparent: true,
      polygonOffset: true,
      polygonOffsetFactor: -0.1,
    });

    this.BuildingMatArr = [
      this._BuildingMaterial1,
      this._BuildingMaterial2,
      this._BuildingMaterial3,
      this._BuildingMaterial4,
      this._BuildingMaterial5,
      this._BuildingMaterial6,
    ];

    this.LeftroadLights();
    this.RightroadLights();

    this.leftTrees();
    this.rightTrees();

    this.leftBuilding();
    this.RightBuilding();

    this.addClouds();
  }

  addClouds() {
    for (let i = 1; i < 5; i++) {
      this["_Cloud" + i] = new THREE.Mesh(
        this._CloudPlane,
        this._CloudMaterial
      );
      this.scene.add(this["_Cloud" + i]);
      // this["_Cloud" + i].scale.set(2, 1, 2);

      const min = 0.2;
      const max = 0.5;
      const randomFloat = (Math.random() * (max - min) + min).toFixed(1);

      let yVal = (Math.random() * (0.35 - 0.2) + 0.2).toFixed(2);
      // this["_Cloud" + i].position.y = 0.2;
      this["_Cloud" + i].position.y = parseFloat(yVal);
      // this["_Cloud" + i].position.z = 0.42;
      let zVal = (Math.random() * (0.59 - 0.55) + 0.55).toFixed(2);
      // console.log(zVal, " zVal ", " yVal ", yVal);
      this["_Cloud" + i].position.z = parseFloat(zVal);

      this["_Cloud" + i].position.x = 0;
      /*  Math.random() > 0.5
          ? this.road1.position.x -
            this.referenceWith -
            this.referenceWith * (Math.random() * (1.8 - 0.8) + 0.8).toFixed(1)
          : this.road1.position.x +
            this.referenceWith +
            this.referenceWith * (Math.random() * (1.8 - 0.8) + 0.8).toFixed(1); */

      if (i == 1) {
        this["_Cloud" + i].position.x =
          this.road1.position.x - this.referenceWith - this.referenceWith * 0.1;
      } else if (i == 2) {
        this["_Cloud" + i].position.x =
          this.road1.position.x + this.referenceWith + this.referenceWith * 0.1;
      } else if (i == 3) {
        this["_Cloud" + i].position.x =
          this.road1.position.x + this.referenceWith + this.referenceWith * 0.2;
      }

      this.CloudArray.push(this["_Cloud" + i]);
    }
  }

  leftBuilding() {
    for (let i = 1; i < 25; i++) {
      let ranMat = Math.floor(Math.random() * 6);

      if (Global.country === "JAPAN" || Global.country === "KOREA") {
        if (ranMat > 3) ranMat = Math.floor(Math.random() * 4);

        if (this.prevRanNum == ranMat) {
          ranMat += 1;
          if (ranMat > 3) ranMat = 0;
        }
      } else {
        if (this.prevRanNum == ranMat) {
          ranMat += 1;
          if (ranMat > 5) ranMat = 0;
        }
      }
      this["_LeftBuilding" + i] = new THREE.Mesh(
        this._BuildingPlane1,
        this.BuildingMatArr[ranMat]
      );
      this.scene.add(this["_LeftBuilding" + i]);
      this["_LeftBuilding" + i].rotation.y = -Math.PI / 0.5;
      if (i == 1) {
        this["_LeftBuilding" + i].position.z =
          this.road1.position.z - this.referenceHeight * 0.37;
      } else {
        this["_LeftBuilding" + i].position.z =
          this["_LeftBuilding" + (i - 1)].position.z +
          this.referenceHeight * 0.25;
      }

      this["_LeftBuilding" + i].position.x = 0.15;
      this["_LeftBuilding" + i].position.y = 0.07;

      if (Global.country === "CHINA") {
        this["_LeftBuilding" + i].position.x = 0.16;
        this["_LeftBuilding" + i].position.y = 0.078;
      } else if (Global.country === "JAPAN") {
        this["_LeftBuilding" + i].position.x = 0.16;
        this["_LeftBuilding" + i].position.y = 0.075;
      }

      this.LeftBuildingArr.push(this["_LeftBuilding" + i]);
      this.prevRanNum = ranMat;
    }
    if (this._LeftBuilding24) this.lastLeftBuilding = this._LeftBuilding24;
  }
  resetLeftBuilding(building) {
    this.resetLeftBuildingBOOL = true;
    // //console.log("resetLeftTreeBOOL");
    building.position.z =
      this.lastLeftBuilding.position.z + this.referenceHeight * 0.25;
    this.lastLeftBuilding = building;
    this.resetLeftBuildingBOOL = false;
  }
  RightBuilding() {
    for (let i = 1; i < 25; i++) {
      let ranMat = Math.floor(Math.random() * 6);

      if (Global.country === "JAPAN" || Global.country === "KOREA") {
        if (ranMat > 3) ranMat = Math.floor(Math.random() * 4);

        if (this.prevRanNum == ranMat) {
          ranMat += 1;
          if (ranMat > 3) ranMat = 0;
        }
      } else {
        if (this.prevRanNum == ranMat) {
          ranMat += 1;
          if (ranMat > 5) ranMat = 0;
        }
      }
      this["_RightBuilding" + i] = new THREE.Mesh(
        this._BuildingPlane1,
        this.BuildingMatArr[ranMat]
      );
      this.scene.add(this["_RightBuilding" + i]);
      this["_RightBuilding" + i].rotation.y = Math.PI / 0.5;
      if (i == 1) {
        this["_RightBuilding" + i].position.z =
          this.road1.position.z - this.referenceHeight * 0.37;
      } else {
        this["_RightBuilding" + i].position.z =
          this["_RightBuilding" + (i - 1)].position.z +
          this.referenceHeight * 0.25;
      }

      this["_RightBuilding" + i].position.x = -0.15;
      this["_RightBuilding" + i].position.y = 0.07;
      if (Global.country === "CHINA") {
        this["_RightBuilding" + i].position.x = -0.16;
        this["_RightBuilding" + i].position.y = 0.078;
      } else if (Global.country === "JAPAN") {
        this["_RightBuilding" + i].position.x = -0.16;
        this["_RightBuilding" + i].position.y = 0.075;
      }
      this.RightBuildingArr.push(this["_RightBuilding" + i]);
      this.prevRanNum = ranMat;
    }
    if (this._RightBuilding24) this.lastRightBuilding = this._RightBuilding24;
  }
  resetRightBuilding(building) {
    this.resetRightBuildingBOOL = true;
    // //console.log("resetLeftTreeBOOL");
    building.position.z =
      this.lastRightBuilding.position.z + this.referenceHeight * 0.25;
    this.lastRightBuilding = building;
    this.resetRightBuildingBOOL = false;
  }

  leftTrees() {
    for (let i = 1; i < 20; i++) {
      let curr_Tree = this._treeMaterial;
      if (Global.country === "CHINA") {
        if (i % 2 == 0) {
          curr_Tree = this._treeMaterial2;
        } else {
          curr_Tree = this._treeMaterial;
        }
      } else if (Global.country === "JAPAN") {
        curr_Tree = this._treeMaterial3;
      }

      this["_TreeMesh_Left" + i] = new THREE.Mesh(this._treePlane, curr_Tree);
      this["_TreeMesh_Left" + i].name = "_TreeMesh_Left" + i;
      if (i == 1) {
        this["_TreeMesh_Left" + i].position.z =
          this.road1.position.z - this.referenceHeight * 0.37;
      } else {
        this["_TreeMesh_Left" + i].position.z =
          this["_TreeMesh_Left" + (i - 1)].position.z +
          this.referenceHeight * 0.2;
      }
      this.scene.add(this["_TreeMesh_Left" + i]);
      this["_TreeMesh_Left" + i].position.y = 0.022;
      this["_TreeMesh_Left" + i].position.x = 0.13;

      this.LeftTreeArray.push(this["_TreeMesh_Left" + i]);
    }
    if (this._TreeMesh_Left19) this.lastLeftTree = this._TreeMesh_Left19;
    //console.log(this.lastLeftTree, " this.lastLeftTree");
  }
  resetLeftTrees(tree) {
    this.resetLeftTreeBOOL = true;
    // //console.log("resetLeftTreeBOOL");
    tree.position.z =
      this.lastLeftTree.position.z + this.referenceHeight * 0.37;
    this.lastLeftTree = tree;
    this.resetLeftTreeBOOL = false;
  }

  rightTrees() {
    for (let i = 1; i < 20; i++) {
      let curr_Tree = this._treeMaterial;
      if (Global.country === "CHINA") {
        if (i % 2 == 0) {
          curr_Tree = this._treeMaterial2;
        } else {
          curr_Tree = this._treeMaterial;
        }
      } else if (Global.country === "JAPAN") {
        curr_Tree = this._treeMaterial3;
      }
      this["_TreeMesh_Right" + i] = new THREE.Mesh(this._treePlane, curr_Tree);
      this["_TreeMesh_Right" + i].name = "_TreeMesh_Right" + i;
      if (i == 1) {
        this["_TreeMesh_Right" + i].position.z =
          this.road1.position.z - this.referenceHeight * 0.37;
      } else {
        this["_TreeMesh_Right" + i].position.z =
          this["_TreeMesh_Right" + (i - 1)].position.z +
          this.referenceHeight * 0.2;
      }
      this.scene.add(this["_TreeMesh_Right" + i]);
      this["_TreeMesh_Right" + i].position.y = 0.022;
      this["_TreeMesh_Right" + i].position.x = -0.13;

      this.RightTreeArray.push(this["_TreeMesh_Right" + i]);
    }
    if (this._TreeMesh_Right19) this.lastRightTree = this._TreeMesh_Right19;
    //console.log(this.lastRightTree, " this.lastRightTree");
  }
  resetRightTrees(tree) {
    this.resetRightTreeBOOL = true;
    //console.log("resetRightTreeBOOL");
    tree.position.z =
      this.lastRightTree.position.z + this.referenceHeight * 0.37;
    this.lastRightTree = tree;
    this.resetRightTreeBOOL = false;
  }
  LeftroadLights() {
    // this._roadLightPlane = new THREE.PlaneGeometry(0.005, 0.005);
    // this._roadLightTexture1 = Global.assets["textures"]["road_light"]["asset"];

    this._roadLightMaterial = new THREE.MeshBasicMaterial({
      map: this._roadLightTexture1,
      side: THREE.DoubleSide,
      transparent: true,
    });

    for (let i = 1; i < 20; i++) {
      this["_LeftRoadLight" + i] = new THREE.Mesh(
        this._roadLightPlane,
        this._roadLightMaterial
      );
      // console.log(this["_LeftRoadLight" + i]);
      this["_LeftRoadLight" + i].name = "_LeftRoadLight" + i;
      this["_LeftRoadLight" + i].position.y = 0.008;
      // this["_roadLight" + i].position.z = this.road1.;
      this["_LeftRoadLight" + i].position.x = 0.09;

      if (i == 1) {
        this["_LeftRoadLight" + i].position.z =
          this.road1.position.z - this.referenceHeight * 0.37;
      } else {
        this["_LeftRoadLight" + i].position.z =
          this["_LeftRoadLight" + (i - 1)].position.z +
          this.referenceHeight * 0.1;
      }

      this.scene.add(this["_LeftRoadLight" + i]);
      this.leftLightArray.push(this["_LeftRoadLight" + i]);
    }
    if (this._LeftRoadLight19) this.LeftLastLight = this._LeftRoadLight19;
    //console.log(this.LeftLastLight, "this.LeftLastLight");
    this.lightBlinkInterval = setTimeout(() => {
      this.leftLightArray.forEach((light) => {
        if (this.blinkLight == false) {
          light.material.map = this._roadLightTexture2;
          this.blinkLight = true;
        } else {
          light.material.map = this._roadLightTexture1;
          this.blinkLight = false;
        }
        light.material.needsUpdate = true;
      });
    }, 100);
  }
  resetLeftLight(light) {
    this.resetLeftLightBOOL = true;
    //console.log("reselett");
    light.position.z =
      this.LeftLastLight.position.z + this.referenceHeight * 0.1;
    this.LeftLastLight = light;
    this.resetLeftLightBOOL = false;
  }
  RightroadLights() {
    // this._roadLightPlane = new THREE.PlaneGeometry(0.005, 0.005);
    // this._roadLightTexture1 = Global.assets["textures"]["road_light"]["asset"];

    this._roadLightMaterial = new THREE.MeshBasicMaterial({
      map: this._roadLightTexture1,
      side: THREE.DoubleSide,
      transparent: true,
    });

    for (let i = 1; i < 20; i++) {
      this["_RightRoadLight" + i] = new THREE.Mesh(
        this._roadLightPlane,
        this._roadLightMaterial
      );
      this["_RightRoadLight" + i].name = "_RightRoadLight" + i;
      this["_RightRoadLight" + i].position.y = 0.01;
      // this["_roadLight" + i].position.z = this.road1.;
      this["_RightRoadLight" + i].position.x = -0.09;

      if (i == 1) {
        this["_RightRoadLight" + i].position.z =
          this.road1.position.z - this.referenceHeight * 0.37;
      } else {
        this["_RightRoadLight" + i].position.z =
          this["_RightRoadLight" + (i - 1)].position.z +
          this.referenceHeight * 0.1;
      }

      this.scene.add(this["_RightRoadLight" + i]);
      this.rightLightArray.push(this["_RightRoadLight" + i]);
    }
    if (this._RightRoadLight19) this.RightLastLight = this._RightRoadLight19;

    this.lightBlinkInterval2 = setTimeout(() => {
      this.rightLightArray.forEach((light) => {
        if (this.blinkLight2 == false) {
          light.material.map = this._roadLightTexture2;
          this.blinkLight2 = true;
        } else {
          light.material.map = this._roadLightTexture1;
          this.blinkLight2 = false;
        }
        light.material.needsUpdate = true;
      });
    }, 100);

    //console.log(this.rightLightArray, " this.rightLightArray");
  }
  resetRightLight(light) {
    this.resetRightLightBOOL = true;
    //console.log("reselett");
    light.position.z =
      this.RightLastLight.position.z + this.referenceHeight * 0.1;
    this.RightLastLight = light;
    this.resetRightLightBOOL = false;
  }
  roadShift() {
    //console.log("roadSHI");
    this.calledRoadShift = true;
    this._currRoad.position.z =
      this.roadArray[2].position.z + this.referenceHeight; /* +
      this.referenceHeight * 0.001; */
    let crossedPlane = this.roadArray.splice(0, 1)[0];
    this.roadArray.push(crossedPlane);
    this._currRoad = this.roadArray[0];
    this.calledRoadShift = false;
    // console.log(" crossedPlane ", crossedPlane);
    crossedPlane.material.map = this.bgTexture;
    crossedPlane.material.aoMap = this.bgTexture;
    crossedPlane.material.needsUpdate = true;
  }
  update(data) {
    // requestAnimationFrame(this.animate);
    // Move both planes towards the camera
    if (Global.startTheGame == false) {
      clearInterval(this.lightBlinkInterval);
      clearInterval(this.lightBlinkInterval2);
    }
    if (this._BigBuilding && Global.startTheGame) {
      if (Global.country == "JAPAN") {
        this.JapanSun.position.z += Global.sceneMoveSpeed;
      }
      this._LeftMountain.position.z += Global.sceneMoveSpeed;
      this._RightMountain.position.z += Global.sceneMoveSpeed;
      this._GreenGround.position.z += Global.sceneMoveSpeed;
      this._BgColor.position.z += Global.sceneMoveSpeed;
      this._BgColor2.position.z += Global.sceneMoveSpeed;
      this._BigBuilding.position.z += Global.sceneMoveSpeed;
      this.camera.position.z += Global.sceneMoveSpeed;

      let camAbsolutePos = this.camera.position.z.toFixed(1);

      if (camAbsolutePos > this.roadCount + 0.4) {
        this.oldCamPos = camAbsolutePos;
        this.roadCount += 1;
        //console.log("III Called");
        if (this.calledRoadShift == false) {
          this.roadShift();
        }
      }

      this.CloudArray.forEach((cloud) => {
        cloud.position.z += Global.sceneMoveSpeed;
        cloud.position.x += 0.0001;
        // console.log(cloud.position.x, "width");
        if (cloud.position.x > 0.5) {
          cloud.position.x = -0.5;
        }
      });
      this.leftLightArray.forEach((light) => {
        if (light.position.z < this.camera.position.z) {
          if (this.resetLeftLightBOOL == false) this.resetLeftLight(light);
        }
      });
      this.rightLightArray.forEach((light) => {
        if (light.position.z < this.camera.position.z) {
          if (this.resetRightLightBOOL == false) this.resetRightLight(light);
        }
      });
      //Left treeLoop
      this.LeftTreeArray.forEach((tree) => {
        if (tree.position.z < this.camera.position.z) {
          if (this.resetLeftTreeBOOL == false) this.resetLeftTrees(tree);
        }
      });
      //Right treeLoop
      this.RightTreeArray.forEach((tree) => {
        if (tree.position.z < this.camera.position.z) {
          if (this.resetRightTreeBOOL == false) this.resetRightTrees(tree);
        }
      });
      //Left BuildingLoop
      this.LeftBuildingArr.forEach((building) => {
        if (building.position.z < this.camera.position.z) {
          if (this.resetLeftBuildingBOOL == false)
            this.resetLeftBuilding(building);
        }
      });
      //Right BuildingLoop
      this.RightBuildingArr.forEach((building) => {
        if (building.position.z < this.camera.position.z) {
          if (this.resetRightBuildingBOOL == false)
            this.resetRightBuilding(building);
        }
      });
    }
  }
}
