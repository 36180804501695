import * as THREE from "three";
import Camera from "./components/Camera";
import Control from "./components/Control";
import EventDispatcher from "./common/EventDispatcher";
import GUI from "./components/GUI";
// import Lights from "./components/Lights";
import AssetLoader from "./components/AssetLoader";
import GameScene from "./components/GameScene";
import { Global } from "./common/global";
// import SwipeControls from "./components/SwipeControls";
import Aeroplane from "./components/Aeroplane";
import HtmlHandler from "./components/HtmlHandler";
import LanguageHandler from "./components/LanguageHandler";

import { Server } from "./common/callServer";

// import jQuery from "jquery";

import { OrbitControls } from "three/addons/controls/OrbitControls.js";

export default class Game extends THREE.Scene {
  environmentMap = null;
  sizes = {
    width: window.innerHeight * 0.56 /* 476 */,
    height: window.innerHeight,
  };

  constructor() {
    super();

    window.addEventListener("resize", this.onResize.bind(this));
  }
  init() {
    // Global.bloomLayer = new THREE.Layers();
    // Global.bloomLayer.set(Global.BLOOM_SCENE);

    this.events = EventDispatcher.getObj();
    this.events.addEventListener("LOADER_EVENTS", this.onEvents.bind(this));

    this.canvas = document.querySelector("canvas.webgl");

    this.clock = new THREE.Clock();

    this.camera = new Camera(
      50,
      this.sizes.width / this.sizes.height,
      0.01,
      10
    );
    this.camera.init();
    this.add(this.camera);

    this.listener = new THREE.AudioListener();
    this.camera.add(this.listener);
    this.audioLoader = new THREE.AudioLoader();

    this.audioLoader.load("./sounds/takeoff.mp3", (buffer) => {
      // this.sound = new THREE.Audio(this.listener);

      Global.takeOffSound = new THREE.Audio(this.listener);
      Global.takeOffSound.setBuffer(buffer);
      Global.takeOffSound.setLoop(false);
      Global.takeOffSound.setVolume(0.5);
    });
    this.audioLoader.load("./sounds/maerskBgMusic.mp3", (buffer) => {
      // this.sound = new THREE.Audio(this.listener);

      Global.bgSound = new THREE.Audio(this.listener);
      Global.bgSound.setBuffer(buffer);
      Global.bgSound.setLoop(true);
      Global.bgSound.setVolume(0.5);
    });

    this.audioLoader.load("./sounds/flightInterior.mp3", (buffer) => {
      Global.flightInteriorSound = new THREE.Audio(this.listener);
      Global.flightInteriorSound.setBuffer(buffer);
      Global.flightInteriorSound.setLoop(true);
      Global.flightInteriorSound.setVolume(0.7);
    });

    this.audioLoader.load("./sounds/negativeSound.mp3", (buffer) => {
      Global.negativeSound = new THREE.Audio(this.listener);
      Global.negativeSound.setBuffer(buffer);
      Global.negativeSound.setLoop(false);
      Global.negativeSound.setVolume(0.5);
    });
    this.audioLoader.load("./sounds/positiveSound.mp3", (buffer) => {
      Global.positiveSound = new THREE.Audio(this.listener);
      Global.positiveSound.setBuffer(buffer);
      Global.positiveSound.setLoop(false);
      Global.positiveSound.setVolume(0.5);
    });

    // this.gui = new GUI();
    // this.gui.init();

    //  this.gui.add(this.GameScene.BasketBallSet.rotation, "x").min(0).max(1);
    //  this.gui.add(this.GameScene.BasketBallSet.rotation, "y").min(-1).max(1);
    //  this.gui.add(this.GameScene.BasketBallSet.rotation, "z").min(0).max(1);

    this.htmlHandler = new HtmlHandler(this, this.camera);
    this.htmlHandler.init();

    this.LanguageHandler = new LanguageHandler(this, this.camera);
    this.LanguageHandler.init();

    this.gameScene = new GameScene(this, this.camera, this.gui);
    this.gameScene.init();
    /* console.log(this.gameScene._BgMaterial, " gamescene");
    colorController.onChange(function (color) {
      this.gameScene._BgMaterial.color.set(color);
      // You can do something with the updated color here.
      console.log("Updated color: " + color);
    }); */

    this.aeroplane = new Aeroplane(this, this.camera);
    this.aeroplane.init();

    // this.FallingItems = new FallingItems(this, this.camera);
    // this.FallingItems.init();

    /* this.cannonDebugRenderer = new CannonDebugger(
      this.gameScene,
      this.aeroplane.scene
    ); */

    // this.lights = new Lights(this);
    // this.lights.init();
    // this.gui.add(this.lights.directionalLight.position, "x").min(-100).max(100);
    // this.gui.add(this.lights.directionalLight.position, "y").min(-100).max(100);
    // this.gui.add(this.lights.directionalLight.position, "z").min(-100).max(100);

    // this.swipeControls = new SwipeControls(this);
    // this.swipeControls.init();

    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
    });
    this.renderer.outputColorSpace = THREE.LinearSRGBColorSpace;
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    this.renderer.toneMapping = THREE.LinearToneMapping;
    this.renderer.toneMappingExposure = 1;
    // this.renderer.useLegacyLights = true;
    this.renderer.setSize(this.sizes.width, this.sizes.height);
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    // this.renderer.physicallyCorrectLights = true;
    // this.renderer.gammaFactor = 2.2;
    /* this.renderer.physicallyCorrectLights = true;
     this.renderer.shadowMap.enabled = true;
     this.renderer.toneMapping = THREE.ReinhardToneMapping;
    this.renderer.gammaInput = true;
    this.renderer.gammaOutput = true; */

    // console.log(this.renderer, "renderer");
    // const controls = new OrbitControls(this.camera, this.renderer.domElement);

    this.assetLoader = new AssetLoader(this);
    this.assetLoader.init();
    this.assetLoader.load();

    this.tick();
    // console.log(this.add,' scene add')
  }
  onEvents(data) {
    switch (data["message"]["event_type"]) {
      case "load_complete":
        this.startGame();
        break;
    }
  }
  startGame() {
    // this.environmentMap = Global.assets["cubemap"]["0"]["asset"];
    // this.environmentMap.mapping = THREE.EquirectangularReflectionMapping;
    // this.environmentMap.encoding = THREE.sRGBEncoding;
    // console.log(this.environment, "environmentenvironment");
    // this.environment
    // this.background = this.environmentMap;
    // console.log(this.background, "this.background");
    // this.background.rotation = 3.14;
    this.backgroundIntensity = 0.0;

    this.events.dispatchEvent({
      type: "GAMESCENE_EVENTS",
      message: {
        event_type: "create_scene",
        data: {},
      },
    });
    /* this.events.dispatchEvent({
      type: "PLAYER_EVENTS",
      message: {
        event_type: "add_spaceship",
        data: {},
      },
    }); */
    /*  this.events.dispatchEvent({
      type: "PLAYER_EVENTS",
      message: {
        event_type: "add_BasketBall_set",
        data: {},
      },
    }); */
    /* this.events.dispatchEvent({
      type: "PLAYER_EVENTS",
      message: {
        event_type: "add_Plane",
        data: {},
      },
    });
    this.events.dispatchEvent({
      type: "PLAYER_EVENTS",
      message: {
        event_type: "add_Items",
        data: {},
      },
    }); */

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const source = urlParams.get("utm_source");
    Global.source = source;
    // console.log(Global.source, "Global.source");

    Global.serverObj = new Server();
    Global.serverObj.send(
      Global.URL_CREATE,
      this.setUID.bind(this),
      null,
      {
        device: Global.isMobile ? "mobile" : "web",
        fresh: true,
        saveType: "create",
        source: Global.source,
      },
      "POST",
      null,
      false
    );
  }
  setUID(data) {
    // console.log(data, "gaggh");
    let v = JSON.parse(data);
    // console.log(v, "gaggh");
    Global.U_ID = v.UID;
    Global.gameKey = v.game_key;

    document.querySelector(".LanguageSelectionDiv").style.display = "block";
    document.querySelector(".lds-hourglass").style.display = "none";

    // console.log(Global.U_ID, " gaggh ", Global.gameKey);
  }
  onResize() {
    // Update sizes
    this.sizes.width = window.innerHeight * 0.56;
    this.sizes.height = window.innerHeight;
    this.events.dispatchEvent({
      type: "CAMERA_EVENTS",
      message: {
        event_type: "resize",
        data: {
          aspect: this.sizes.width / this.sizes.height,
        },
      },
    });
    this.renderer.setSize(this.sizes.width, this.sizes.height);
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    this.renderer.shadowMap.enabled = true;
    // this.cannonDebugRenderer.update();
  }
  tick() {
    const elapsedTime = 1; //this.clock.getElapsedTime();

    let delta = this.clock.getDelta();
    // console.log(delta, " delta");
    // delta *= 10000;

    let ticks = Math.round(delta / (1 / 240));
    // console.log("console.log(ticks)", delta);
    ticks = Math.min(ticks, 20);

    for (let i = 0; i < ticks; i++) {
      // this.update(now, delta / ticks);
      // console.log("KK");
      this.events.dispatchEvent({
        type: "GAMESCENE_EVENTS",
        message: {
          event_type: "update",
          data: {
            elapsedTime: elapsedTime,
          },
        },
      });
    }

    // this.simulate();
    this.renderer.render(this, this.camera);
    window.requestAnimationFrame(this.tick.bind(this));
  }
}

const game = new Game();
game.init();
