let Global = {
  BLOOM_SCENE: 1,
  itemsToRayCastRight: [],
  itemsToRayCastLeft: [],
  itemsToRayCastDown: [],
  itemsToRayCastUp: [],
  BasketCount: 0,
  country: "CHINA",
  time: 0,
  takeOffSound: null,
  bgSound: null,
  flightInteriorSound: null,
  negativeSound: null,
  positiveSound: null,
  finalScore: 0,
  topScoreOfDay: 0,
  language: null,
  sceneMoveSpeed: 0.0015,

  willPlayMusic: true,

  gameTry: 1,

  source: "Social",

  U_ID: null,
  gameKey: null,

  startTheGame: false,

  URL_CREATE: "https://score.maerskpickerpilot.com/createUser.php",
  URL_VALUEupdater: "https://score.maerskpickerpilot.com/valueUpdator.php",
  WEBSERVICE: "https://score.maerskpickerpilot.com/webservice.php",

  // GENERATE: "https://newsfeedsmartapp.com/demo/Abhishek/Maersk/generate.php",

  assets: {
    glbs: {
      AEROplane: {
        key: "AEROplane",
        path: ["./models/aeroplane.glb"],
        asset: null,
      },
      /* gem: {
        key: "gem",
        path: ["/models/gem.glb"],
        asset: null,
      },
      spaceship: {
        key: "spaceship",
        path: ["/models/spaceship.glb"],
        asset: null,
      },
      basketBallSet: {
        key: "basketBallSet",
        path: ["/models/basketball_set.glb"],
        asset: null,
      },
      basketBallGround: {
        key: "basketBallGround",
        path: ["/models/basketBallGround.glb"],
        asset: null,
      },
      basketBall: {
        key: "basketBall",
        path: ["/models/basketball_ball.glb"],
        asset: null,
      },
      GoldenBall: {
        key: "GoldenBall",
        path: ["/models/basketball_golden_ball.glb"],
        asset: null,
      }, */
    },
    textures: {
      //TRESS
      tree: {
        key: "tree",
        path: ["./textures/tree/Tree.png"],
        asset: null,
      },
      tree2: {
        key: "tree2",
        path: ["./textures/tree/Tree2.png"],
        asset: null,
      },
      tree3: {
        key: "tree3",
        path: ["./textures/tree/Tree3.png"],
        asset: null,
      },
      //Side Buildinga
      USA_building1: {
        key: "USA_building1",
        path: ["./textures/sideBuildings/1.png"],
        asset: null,
      },
      USA_building2: {
        key: "USA_building2",
        path: ["./textures/sideBuildings/2.png"],
        asset: null,
      },
      USA_building3: {
        key: "USA_building3",
        path: ["./textures/sideBuildings/3.png"],
        asset: null,
      },
      USA_building4: {
        key: "USA_building4",
        path: ["./textures/sideBuildings/4.png"],
        asset: null,
      },
      USA_building5: {
        key: "USA_building5",
        path: ["./textures/sideBuildings/5.png"],
        asset: null,
      },
      USA_building6: {
        key: "USA_building6",
        path: ["./textures/sideBuildings/6.png"],
        asset: null,
      },
      CHINA_building1: {
        key: "CHINA_building1",
        path: ["./textures/sideBuildings/7.png"],
        asset: null,
      },
      CHINA_building2: {
        key: "CHINA_building2",
        path: ["./textures/sideBuildings/8.png"],
        asset: null,
      },
      CHINA_building3: {
        key: "CHINA_building3",
        path: ["./textures/sideBuildings/9.png"],
        asset: null,
      },
      CHINA_building4: {
        key: "CHINA_building4",
        path: ["./textures/sideBuildings/10.png"],
        asset: null,
      },
      CHINA_building5: {
        key: "CHINA_building5",
        path: ["./textures/sideBuildings/11.png"],
        asset: null,
      },
      CHINA_building6: {
        key: "CHINA_building6",
        path: ["./textures/sideBuildings/12.png"],
        asset: null,
      },
      JAPAN_building1: {
        key: "JAPAN_building1",
        path: ["./textures/sideBuildings/13.png"],
        asset: null,
      },
      JAPAN_building2: {
        key: "JAPAN_building2",
        path: ["./textures/sideBuildings/14.png"],
        asset: null,
      },
      JAPAN_building3: {
        key: "JAPAN_building3",
        path: ["./textures/sideBuildings/15.png"],
        asset: null,
      },
      JAPAN_building4: {
        key: "JAPAN_building4",
        path: ["./textures/sideBuildings/16.png"],
        asset: null,
      },
      KOREA_building1: {
        key: "KOREA_building1",
        path: ["./textures/sideBuildings/17.png"],
        asset: null,
      },
      KOREA_building2: {
        key: "KOREA_building2",
        path: ["./textures/sideBuildings/18.png"],
        asset: null,
      },
      KOREA_building3: {
        key: "KOREA_building3",
        path: ["./textures/sideBuildings/19.png"],
        asset: null,
      },
      KOREA_building4: {
        key: "KOREA_building4",
        path: ["./textures/sideBuildings/20.png"],
        asset: null,
      },
      //Mountains
      USA_LeftMountain: {
        key: "USA_LeftMountain",
        path: ["./textures/mountains/Left-mountain.png"],
        asset: null,
      },
      USA_RightMountain: {
        key: "USA_RightMountain",
        path: ["./textures/mountains/Right-Mountain.png"],
        asset: null,
      },
      CHINA_LeftMountain: {
        key: "USA_LeftMountain",
        path: ["./textures/mountains/ChinaLeft.png"],
        asset: null,
      },
      CHINA_RightMountain: {
        key: "USA_RightMountain",
        path: ["./textures/mountains/ChinaRight.png"],
        asset: null,
      },
      JAPAN_LeftMountain: {
        key: "USA_LeftMountain",
        path: ["./textures/mountains/JapanLeft.png"],
        asset: null,
      },
      JAPAN_RightMountain: {
        key: "USA_RightMountain",
        path: ["./textures/mountains/JapanRight.png"],
        asset: null,
      },
      KOREA_LeftMountain: {
        key: "USA_LeftMountain",
        path: ["./textures/mountains/KoreaLeft.png"],
        asset: null,
      },
      KOREA_RightMountain: {
        key: "USA_RightMountain",
        path: ["./textures/mountains/KoreaRight.png"],
        asset: null,
      },
      Cloud: {
        key: "Cloud",
        path: ["./textures/mountains/CCloud.png"],
        asset: null,
      },
      Sun: {
        key: "Sun",
        path: ["./textures/mountains/Sun.png"],
        asset: null,
      },
      Item1: {
        key: "Item1",
        path: ["./textures/Items/Collectable1.png"],
        asset: null,
      },
      Item2: {
        key: "Item2",
        path: ["./textures/Items/Collectable2.png"],
        asset: null,
      },
      Item3: {
        key: "Item3",
        path: ["./textures/Items/Obstacle-1.png"],
        asset: null,
      },
      plusTwo: {
        key: "plusTwo",
        path: ["./textures/scores/plusTwo.png"],
        asset: null,
      },
      plusFive: {
        key: "plusFive",
        path: ["./textures/scores/plusFive.png"],
        asset: null,
      },
      minusTwo: {
        key: "minusTwo",
        path: ["./textures/scores/minusTwo.png"],
        asset: null,
      },
      /////////////
      /* tree_color: {
        key: "tree_color",
        path: ["./textures/tree/color.jpg"],
        asset: null,
      }, */
      road: {
        key: "road",
        path: ["./textures/road/Road.png"],
        asset: null,
      },
      road2: {
        key: "road2",
        path: ["./textures/road/Road2.png"],
        asset: null,
      },
      road_light1: {
        key: "road_light1",
        path: ["./textures/road/Road-light.png"],
        asset: null,
      },
      road_light2: {
        key: "road_light2",
        path: ["./textures/road/Road-light2.png"],
        asset: null,
      },
      //Big Buildings
      USA_bigbuilding: {
        key: "USA_bigbuilding",
        path: ["./textures/bigbuilding/Bg-Building.png"],
        asset: null,
      },
      CHINA_bigbuilding: {
        key: "CHINA_bigbuilding",
        path: ["./textures/bigbuilding/China_BG_Building.png"],
        asset: null,
      },
      JAPAN_bigbuilding: {
        key: "JAPAN_bigbuilding",
        path: ["./textures/bigbuilding/Japan_BG_Building.png"],
        asset: null,
      },
      KOREA_bigbuilding: {
        key: "KOREA_bigbuilding",
        path: ["./textures/bigbuilding/South_korea_BG_Building.png"],
        asset: null,
      },

      plane: {
        key: "plane",
        path: ["./textures/aeroplane/Plane.png"],
        asset: null,
      },

      /* 
      mountain_color: {
        key: "track_color",
        path: ["./textures/mountain/mountain.jpg"],
        asset: null,
      },
      mountain_color2: {
        key: "track_color2",
        path: ["./textures/mountain/mountain.jpg"],
        asset: null,
      },
      mountain_normal: {
        key: "track_normal",
        path: ["./textures/mountain/normal.png"],
        asset: null,
      }, */
    },
  },
};
export { Global };
