import * as THREE from "three";
import EventDispatcher from "../common/EventDispatcher";
import { Global } from "../common/global";
import * as CANNON from "cannon-es";

import { gsap, Power2 } from "gsap";

import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js";
import { TextGeometry } from "three/addons/geometries/TextGeometry.js";

export default class Aeroplane {
  doRayCast = false;
  raycaster = new THREE.Raycaster();
  raycaster2 = new THREE.Raycaster();

  rayDirLeft = new THREE.Vector3(1, 0, 1);
  rayDirRight = new THREE.Vector3(-1, 0, 1);
  constructor(scene, camera) {
    this.scene = scene;
    this.camera = camera;

    this.startClickPos = new THREE.Vector3();

    document.addEventListener("keydown", this.handleKeyPress.bind(this));
    document.addEventListener("keyup", this.handleKeyUp.bind(this));
  }
  init() {
    this.score = 0;
    this.referenceWith = 0.25;
    this.moveSpeed = 0.015;
    this.canMove = false;
    this.moveLeft = false;
    this.moveRight = false;
    this.tweenPlane = false;

    this.pointTweenCnt = 0;

    this.characterPositionX = 0;
    this.targetPositionX = 0;
    this.lerpSpeed = 0.3;

    this.tweenCnt = 0;

    // this.boundingBoxArray = [];

    this.referenceWidth = 0.25;
    this.resetItemBOOL = false;
    this.lastItem = null;
    this.GeneratedItemArr = [];

    this.events = EventDispatcher.getObj();
    this.events.addEventListener("PLAYER_EVENTS", this.onEvents.bind(this));
    this.events.addEventListener("GAMESCENE_EVENTS", this.onEvents.bind(this));
    this.events.addEventListener(
      "GAMECONTROL_EVENTS",
      this.onEvents.bind(this)
    );

    /*  window.addEventListener("mousedown", (event) => {
      console.log(" dir mouseDOWN");
      // Calculate the click position in normalized device coordinates (-1 to 1)
      const mouseX = (event.clientX / window.innerWidth) * 2 - 1;

      // Check if the click was on the left or right side of the screen
      if (mouseX < 0) {
        // cube.position.x -= 1;
        console.log("LEFT direction");
        this.handleLeftTouch();
      } else {
        // cube.position.x += 1;
        console.log("RIGHT direction");
        this.handleRightTouch();
      }
    }); */

    /* window.addEventListener("mouseup", () => {
      console.log("dir MOUSEUp");
      // this.handleKeyUp();
    }); */
  }

  onEvents(data) {
    switch (data["message"]["event_type"]) {
      case "add_Plane":
        this.addPlane();
        break;
      case "add_Items":
        this.addItems();
        break;
      case "add_takeoffPlane":
        this.addTakeOffPlane();
        break;
      case "resetPlanes":
        this.resetPlanes();
        break;

      case "update":
        this.update();
        break;
    }
  }
  handleKeyUp() {
    this.canMove = false;
    /*  this.tweenPlane = false;
    gsap.to(this._aeroplaneCharacter.rotation, {
      duration: 0.1,
      z: 0,
      repeat: 0,
      ease: "none",
    }); */
  }

  handleKeyPress(e) {
    this.canMove = true;
    if (e.key === "a" || e.key === "A" || e.key === "ArrowLeft") {
      // console.log("AAAAAA");

      this.moveLeft = true;
      this.moveRight = false;
    } else if (e.key === "d" || e.key === "D" || e.key === "ArrowRight") {
      // console.log("AAAAAADD");
      this.moveLeft = false;
      this.moveRight = true;
    }
  }
  handleRightTouch() {
    this.canMove = true;

    this.moveLeft = false;
    this.moveRight = true;

    document.querySelector(".steeringDiv").style.transform = "rotateZ(45deg)";
  }

  handleTouchOut() {
    this.canMove = false;

    this.moveLeft = false;
    this.moveRight = false;

    document.querySelector(".steeringDiv").style.transform = "rotateZ(0deg)";
  }
  handleLeftTouch() {
    this.canMove = true;

    this.moveLeft = true;
    this.moveRight = false;

    document.querySelector(".steeringDiv").style.transform = "rotateZ(-45deg)";
  }

  onMouseDown(event) {
    this.startClickPos.set(event.x, event.y, 0);
  }
  onMouseUp(event) {
    // this.shoot(event);
  }

  addPlane() {
    this._aeroPlaneTexture = Global.assets["textures"]["plane"]["asset"];
    this._aeroPlane = new THREE.PlaneGeometry(2.07 * 0.033, 1 * 0.033);

    this._aeroPlaneMaterial = new THREE.MeshBasicMaterial({
      map: this._aeroPlaneTexture,
      side: THREE.DoubleSide,
      transparent: true,
    });
    this._aeroplaneCharacter = new THREE.Mesh(
      this._aeroPlane,
      this._aeroPlaneMaterial
    );
    this._aeroplaneCharacter.position.y = 0.01;
    this._aeroplaneCharacter.position.z = -0.25;
    this._aeroplaneCharacter.rotation.x = Math.PI / 3;

    this.plusTwoTexture = Global.assets["textures"]["plusTwo"]["asset"];
    this.plusFiveTexture = Global.assets["textures"]["plusFive"]["asset"];
    this.minusTwoTexture = Global.assets["textures"]["minusTwo"]["asset"];

    this.plusTwoPlane = new THREE.PlaneGeometry(0.02, 0.02);

    this.plusTwoPlaneMaterial = new THREE.MeshBasicMaterial({
      map: this.plusTwoTexture,
      side: THREE.DoubleSide,
      transparent: true,
    });
    this.plusFivePlaneMaterial = new THREE.MeshBasicMaterial({
      map: this.plusFiveTexture,
      side: THREE.DoubleSide,
      transparent: true,
    });
    this.minusTwoPlaneMaterial = new THREE.MeshBasicMaterial({
      map: this.minusTwoTexture,
      side: THREE.DoubleSide,
      transparent: true,
    });

    this.plusTwoPoint = new THREE.Mesh(
      this.plusTwoPlane,
      this.plusTwoPlaneMaterial
    );
    this.plusFivePoint = new THREE.Mesh(
      this.plusTwoPlane,
      this.plusFivePlaneMaterial
    );
    this.minusTwoPoint = new THREE.Mesh(
      this.plusTwoPlane,
      this.minusTwoPlaneMaterial
    );

    this.scene.add(this.plusTwoPoint);
    this.scene.add(this.plusFivePoint);
    this.scene.add(this.minusTwoPoint);
    this.plusTwoPoint.position.z = -1;
    this.plusTwoPoint.position.y = 0.05;
    this.plusTwoPoint.rotation.y = -Math.PI;

    this.plusFivePoint.position.z = -0.5;
    this.plusFivePoint.position.y = 0.05;
    this.plusFivePoint.rotation.y = -Math.PI;

    this.minusTwoPoint.position.z = -1.5;
    this.minusTwoPoint.position.y = 0.05;
    this.minusTwoPoint.rotation.y = -Math.PI;

    // this._aeroplaneCharacter.rotation.z = 0.2;
    // var axis = new THREE.Vector3(4, 0, 70).normalize();
    // this._aeroplaneCharacter.rotateOnWorldAxis(axis, 0.01);

    // console.log(this._aeroplaneCharacter, " Balls");
    this.scene.add(this._aeroplaneCharacter);
    // console.log(this._aeroplaneCharacter.position.x, "AAAAAADD");

    this.doRayCast = true;

    document
      .querySelector(".rightArrowDiv")
      .addEventListener("pointerdown", (e) => {
        e.preventDefault();
        e.stopImmediatePropagation();
        this.handleRightTouch();
      });
    document
      .querySelector(".rightArrowDiv")
      .addEventListener("pointerup", () => {
        this.handleTouchOut();
      });
    document
      .querySelector(".leftArrowDiv")
      .addEventListener("pointerdown", (e) => {
        e.preventDefault();
        e.stopImmediatePropagation();
        this.handleLeftTouch();
      });
    document
      .querySelector(".leftArrowDiv")
      .addEventListener("pointerup", () => {
        this.handleTouchOut();
      });
    // this.addTakeOffPlane();
    this.PlaneModel = Global.assets["glbs"]["AEROplane"]["asset"];
    // console.log(this.PlaneModel, "planemodel");

    this.PlaneModel.scene.scale.set(0.01, 0.01, 0.01);
  }
  resetPlanes() {
    // this.scene.remove(this.PlaneModel.scene);
    this._aeroplaneCharacter.position.x = 0;
    this._aeroplaneCharacter.visible = true;
    document.querySelector(".speedMeter").style.opacity = "1";
  }
  addTakeOffPlane() {
    this.score = 0;
    this.scene.add(this.PlaneModel.scene);
    // console.log(this.PlaneModel, "planemodel");
    this._aeroplaneCharacter.visible = false;

    this.minusTwoPoint.visible = false;
    this.plusFivePoint.visible = false;
    this.plusTwoPoint.visible = false;

    if (Global.willPlayMusic) Global.takeOffSound.play();
    /* this.PlaneModel = Global.assets["glbs"]["AEROplane"]["asset"];
    console.log(this.PlaneModel, "planemodel");
    this.scene.add(this.PlaneModel.scene); */
    this.PlaneModel.scene.position.set(0, 0.05, 0.1);
    this.PlaneModel.scene.position.y = this._aeroplaneCharacter.position.y;
    this.PlaneModel.scene.position.z = this._aeroplaneCharacter.position.z;
    this.PlaneModel.scene.scale.set(0.01, 0.01, 0.01);
    this.PlaneModel.scene.rotation.y = Math.PI;
    this.PlaneModel.scene.rotation.x = 0;
    this.PlaneModel.scene.rotation.z = 0;

    this._planeWhiteMaterial = new THREE.MeshBasicMaterial({
      color: 0xffffff,
      side: THREE.DoubleSide,
      polygonOffset: true,
      polygonOffsetFactor: -0.1,
    });
    this._planeBlueMaterial = new THREE.MeshBasicMaterial({
      color: 0x95dfea,
      side: THREE.DoubleSide,
      polygonOffset: true,
      polygonOffsetFactor: -0.1,
    });

    this.PlaneModel.scene.children[0].children[0].material =
      this._planeBlueMaterial;
    this.PlaneModel.scene.children[0].children[1].material =
      this._planeWhiteMaterial;
    // this.tween1 = null;

    if (this.tweenCnt > 0) {
      // console.log(this["postionTween" + (this.tweenCnt - 1)], "OOJN");
      this["postionTween" + (this.tweenCnt - 1)].kill();
      this["rotationTween" + (this.tweenCnt - 1)].kill();
    }

    this["postionTween" + this.tweenCnt] = gsap.to(
      this.PlaneModel.scene.position,
      {
        y: 10,
        z: this._aeroplaneCharacter.position.z + 18,
        duration: 500,
        ease: Power2.easeOut,
      }
    );
    this["rotationTween" + this.tweenCnt] = gsap.to(
      this.PlaneModel.scene.rotation,
      {
        x: -18,
        duration: 500,
        ease: Power2.easeOut,
      }
    );
    // console.log(this["postionTween" + this.tweenCnt], "this.tween1 ");
    // console.log(this["rotationTween" + this.tweenCnt], "this.tween2 ");
    this.tweenCnt += 1;
  }

  //Add Items
  addItems() {
    this.itemTexture1 = Global.assets["textures"]["Item1"]["asset"];
    this.itemTexture2 = Global.assets["textures"]["Item2"]["asset"];
    this.itemTexture3 = Global.assets["textures"]["Item3"]["asset"];
    this.itemPlane = new THREE.PlaneGeometry(0.69 * 0.03, 1 * 0.03);

    this.ItemMaterial1 = new THREE.MeshBasicMaterial({
      map: this.itemTexture1,
      side: THREE.DoubleSide,
      transparent: true,
    });
    this.ItemMaterial1.name = "Positive5";
    this.ItemMaterial2 = new THREE.MeshBasicMaterial({
      map: this.itemTexture2,
      side: THREE.DoubleSide,
      transparent: true,
    });
    this.ItemMaterial2.name = "Positive2";
    this.ItemMaterial3 = new THREE.MeshBasicMaterial({
      map: this.itemTexture3,
      side: THREE.DoubleSide,
      transparent: true,
    });
    this.ItemMaterial3.name = "Negative";

    this.ItemMaterialArray = [
      this.ItemMaterial1,
      this.ItemMaterial2,
      this.ItemMaterial3,
    ];
    this.addIng();
  }
  addIng() {
    for (let i = 1; i < 21; i++) {
      let ranMat = Math.floor(Math.random() * 3);
      this["item" + i] = new THREE.Mesh(
        this.itemPlane,
        this.ItemMaterialArray[ranMat]
      );
      // console.log(this.ItemMaterialArray[ranMat], "rand");
      this["item" + i].position.y = 0.015;

      // this["boundingBox" + i] = new THREE.Box3().setFromObject(
      //   this["item" + i]
      // );

      let xPos = (Math.random() * (0.27 - 0.15) + 0.15).toFixed(2);
      this["item" + i].position.x =
        Math.random() > 0.5
          ? this["item" + i].position.x - this.referenceWidth * parseFloat(xPos)
          : this["item" + i].position.x +
            this.referenceWidth * parseFloat(xPos);

      let zPos = (Math.random() * (0.3 - 0.2) + 0.2).toFixed(1);
      if (i > 1) {
        this["item" + i].position.z =
          this["item" + (i - 1)].position.z + parseFloat(zPos);
        // console.log(this["item" + (i - 1)].position.z + zPos, "   JJJJ");
      } else {
        this["item" + i].position.z = 0;
      }

      if (this.item20) {
        this.lastItem = this.item20;
        // console.log(this.lastItem, "this.lastItem");
      }

      this.GeneratedItemArr.push(this["item" + i]);

      // this.boundingBoxArray.push(this["boundingBox" + i]);
      // this.catherBox = new THREE.Box3().setFromObject(this._aeroPlaneMaterial);
      this["item" + i].isHit = false;
      // console.log(this["item" + i], "KLKK");
      this.scene.add(this["item" + i]);
    }
  }

  resetItem(itm) {
    this.resetItemBOOL = true;
    let newZpos = (Math.random() * (0.3 - 0.2) + 0.2).toFixed(1);
    itm.position.z = this.lastItem.position.z + parseFloat(newZpos);
    itm.isHit = false;
    itm.visible = true;
    this.lastItem = itm;
    this.resetItemBOOL = false;
    // console.log("resetItem");
  }

  moveLeftFn() {
    this.characterPositionX = THREE.MathUtils.lerp(
      this.characterPositionX,
      this.targetPositionX,
      this.lerpSpeed
    );
    // console.log(this.characterPositionX, "this.characterPositionX");
    this._aeroplaneCharacter.position.x = this.characterPositionX;
  }
  moveRightFn() {
    this.characterPositionX = THREE.MathUtils.lerp(
      this.characterPositionX,
      this.targetPositionX,
      this.lerpSpeed
    );
    // console.log(this.characterPositionX, "this.characterPositionX");
    this._aeroplaneCharacter.position.x = this.characterPositionX;
  }
  pointManager(pointName) {
    // console.log(pointName, "pointName");
    this.minusTwoPoint.visible = true;
    this.plusFivePoint.visible = true;
    this.plusTwoPoint.visible = true;
    if (this.pointTweenCnt > 0) {
      this["pointTween" + (this.pointTweenCnt - 1)].kill();
    }
    if (pointName.includes("Negative")) {
      this.minusTwoPoint.position.z = this._aeroplaneCharacter.position.z;
      this.minusTwoPoint.position.x = this._aeroplaneCharacter.position.x;
      this.minusTwoPoint.position.y = this._aeroplaneCharacter.position.y;
      this["pointTween" + this.pointTweenCnt] = gsap.to(
        this.minusTwoPoint.position,
        {
          y: 0.05,
          x: 0,
          duration: 1, // Adjust the duration as needed
          ease: Power2.easeOut, // Use a different ease function if desired
        }
      );
      if (this.score > 0) {
        this.score -= 2;
        if (Global.willPlayMusic) {
          if (Global.negativeSound.isPlaying) {
            Global.negativeSound.stop();
            Global.negativeSound.play();
          } else {
            Global.negativeSound.play();
          }
        }
      }
    } else {
      if (pointName.includes("Positive5")) {
        this.plusFivePoint.position.z = this._aeroplaneCharacter.position.z;
        this.plusFivePoint.position.x = this._aeroplaneCharacter.position.x;
        this.plusFivePoint.position.y = this._aeroplaneCharacter.position.y;
        this["pointTween" + this.pointTweenCnt] = gsap.to(
          this.plusFivePoint.position,
          {
            y: 0.05,
            x: 0,
            duration: 1, // Adjust the duration as needed
            ease: Power2.easeOut, // Use a different ease function if desired
          }
        );
        this.score += 5;
        if (Global.willPlayMusic) {
          if (Global.positiveSound.isPlaying) {
            Global.positiveSound.stop();
            Global.positiveSound.play();
          } else {
            Global.positiveSound.play();
          }
        }
      } else {
        this.plusTwoPoint.position.z = this._aeroplaneCharacter.position.z;
        this.plusTwoPoint.position.x = this._aeroplaneCharacter.position.x;
        this.plusTwoPoint.position.y = this._aeroplaneCharacter.position.y;
        this["pointTween" + this.pointTweenCnt] = gsap.to(
          this.plusTwoPoint.position,
          {
            y: 0.05,
            x: 0,
            duration: 1, // Adjust the duration as needed
            ease: Power2.easeOut, // Use a different ease function if desired
          }
        );
        this.score += 2;
        if (Global.willPlayMusic) {
          if (Global.positiveSound.isPlaying) {
            Global.positiveSound.stop();
            Global.positiveSound.play();
          } else {
            Global.positiveSound.play();
          }
        }
      }
    }
    document.querySelector(".scoreValueTxt").innerHTML =
      this.score < 10 ? "0" + this.score : this.score;
    Global.finalScore = this.score;

    this.pointTweenCnt += 1;
  }

  update() {
    if (this._aeroplaneCharacter && Global.startTheGame)
      this._aeroplaneCharacter.position.z += Global.sceneMoveSpeed;

    if (this.doRayCast) {
      this.catcherPosition = this._aeroplaneCharacter.position.clone();
      this.raycaster.set(this.catcherPosition, this.rayDirRight);

      const intersections = this.raycaster.intersectObjects(
        this.GeneratedItemArr
      );
      if (intersections.length > 0 && intersections[0].distance <= 0.03) {
        const collidedObject = intersections[0].object;
        // console.log(intersections, "intersections");

        if (collidedObject.isHit == false) {
          collidedObject.isHit = true;
          collidedObject.visible = false;
          this.pointManager(collidedObject.material.name);
        }
      }

      this.catcherPosition2 = this._aeroplaneCharacter.position.clone();
      this.raycaster2.set(this.catcherPosition2, this.rayDirLeft);
      const intersections2 = this.raycaster2.intersectObjects(
        this.GeneratedItemArr
      );
      if (intersections2.length > 0 && intersections2[0].distance <= 0.03) {
        const collidedObject = intersections2[0].object;
        // console.log(intersections2, "intersections2");

        if (collidedObject.isHit == false) {
          collidedObject.isHit = true;
          collidedObject.visible = false;
          this.pointManager(collidedObject.material.name);
        }
      }
    }

    if (this.canMove && Global.startTheGame) {
      if (this.moveLeft) {
        if (
          this._aeroplaneCharacter.position.x <
          this.referenceWith - this.referenceWith * 0.8
        ) {
          // this._aeroplaneCharacter.translateX(this.moveSpeed);
          this.targetPositionX =
            this._aeroplaneCharacter.position.x + this.moveSpeed;
          // this.moveLeftFn();
          this.characterPositionX = THREE.MathUtils.lerp(
            this.characterPositionX,
            this.targetPositionX,
            this.lerpSpeed
          );
          // this._aeroplaneCharacter.position.x = this.characterPositionX;
          gsap.to(this._aeroplaneCharacter.position, {
            x: this.characterPositionX,
            duration: 0.5, // Adjust the duration as needed
            ease: Power2.easeOut, // Use a different ease function if desired
          });
        }
      } else if (this.moveRight) {
        if (
          this._aeroplaneCharacter.position.x >
          -(this.referenceWith - this.referenceWith * 0.792)
        ) {
          this.targetPositionX =
            this._aeroplaneCharacter.position.x - this.moveSpeed;
          this.characterPositionX = THREE.MathUtils.lerp(
            this.characterPositionX,
            this.targetPositionX,
            this.lerpSpeed
          );
          gsap.to(this._aeroplaneCharacter.position, {
            x: this.characterPositionX,
            duration: 0.5, // Adjust the duration as needed
            ease: Power2.easeOut, // Use a different ease function if desired
          });
          // console.log(this.characterPositionX, "this.characterPositionX");
          // this._aeroplaneCharacter.position.x = this.characterPositionX;
          // this._aeroplaneCharacter.translateX(-this.moveSpeed);
          // this.moveRightFn();
        }
      }
    }

    if (this.GeneratedItemArr.length > 18) {
      this.GeneratedItemArr.forEach((itm) => {
        if (itm.position.z < this.camera.position.z) {
          // console.log("GAGAGAG");
          if (this.resetItemBOOL == false) this.resetItem(itm);
        }
      });
    }
  }
}
