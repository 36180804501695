import { Global } from "../common/global";
import EventDispatcher from "../common/EventDispatcher";

export default class LanguageHandler {
  constructor(scene) {
    this.scene = scene;
  }
  init() {
    this.events = EventDispatcher.getObj();
    this.events.addEventListener("PLAYER_EVENTS", this.onEvents.bind(this));
  }
  onEvents(data) {
    switch (data["message"]["event_type"]) {
      case "setChinese":
        this.setChinese();
        break;
    }
  }
  setChinese() {
    console.log("CHINESE");
    //start text
    document.querySelector(".StartText").innerHTML = "开始";

    document.querySelector(".linkedInDiv").style.display = "none";
    document.querySelector(".fbDiv").style.display = "none";
    document.querySelector(".weChat").style.display = "block";

    //Instruction Page top text
    document.querySelector(".IP_topText").innerHTML =
      "您能以多快的速度将半导体芯片从代工厂提货并运送到下一个目的地？这一切将尽在您的掌握之中。";

    // HOW to Play text
    document.querySelector(".HowToPlayTxt").innerHTML = "游戏玩法";

    //choose a location
    document.querySelector(".firstRowTxt").innerHTML = "选择一个地点";

    //Be ready to pick
    document.querySelector(".secondRowTxt").innerHTML =
      "马士基空运服务时刻准备就绪，将您的半导体芯片运送到目的地";

    //Pick as many chips
    document.querySelector(".thirdRowTxt").innerHTML =
      "在60秒内,通过左右(或键盘按键<>)移动飞机方向盘获得更多半导体芯片";

    //the golden chips win you extra point
    document.querySelector(".fourthRowTxt").innerHTML =
      "获取黄金芯片将有额外分数";

    document.querySelector(".insideIntructionBox").style.fontSize = "0.9rem";

    document.querySelector(".fifthRowTxt").innerHTML = "避开路面障碍物。";
    //Ready to Go
    document.querySelector(".ReadyToGoText").innerHTML = "准备开始";

    //choose your location
    document.querySelector(".chooseLocationTxt").innerHTML = "选择你的取货地点";

    //Country Image CHange
    document.querySelector(".CHINAimg").src =
      "https://newsfeedsmartapp.com/demo/Abhishek/MaerskAssets/CHINESE_CHINA.png";
    document.querySelector(".JAPANimg").src =
      "https://newsfeedsmartapp.com/demo/Abhishek/MaerskAssets/CHINESE_JAPAN.png";
    document.querySelector(".KOREAimg").src =
      "https://newsfeedsmartapp.com/demo/Abhishek/MaerskAssets/CHINESE_KOREA.png";
    document.querySelector(".CHINAimg").src =
      "https://newsfeedsmartapp.com/demo/Abhishek/MaerskAssets/CHINESE_CHINA.png";
    document.querySelector(".USAimg").src =
      "https://newsfeedsmartapp.com/demo/Abhishek/MaerskAssets/CHINESE_USA.png";

    //Play Text
    document.querySelector(".PlayText").innerHTML = "开始";

    //Getting ready to dispatch
    document.querySelector(".progressBarText").innerHTML = "准备发货中";

    //PICK UP complete ready to fly
    document.querySelector(".pickUpTxt").innerHTML = "完成取货，准备起飞！";
    document.querySelector(".semicondTxt").innerHTML = "您的半导体芯片将在";
    document.querySelector(".lessThanTxt").innerHTML = "72 小时内达到";

    // document.querySelector(".semicondTxt").style.fontSize = "1rem !important";

    document.querySelector(".congratulationTxt").innerHTML = "恭喜您！";
    document.querySelector(".youHaveScoreTxt").innerHTML = "你的分数是";
    document.querySelector(".pointsTxt").innerHTML = "";
    document.querySelector(".NextText").innerHTML = "下一步";

    document.querySelector(".SharePageFirstText").innerHTML =
      "准备好在现实生活中控制半" + "<br>" + "导体物流供应链速度了吗？";
    document.querySelector(".learnMoreTxt").innerHTML = "了解更多 ";
    document.querySelector(".secondText").innerHTML = "分享你的分数到 ";

    document.querySelector(".firstLineDiv").style.fontSize = "1.1rem";
    document.querySelector(".firstLineDiv").style.top = "12%";
    document.querySelector(".LearnMoreBtnDiv").style.top = "27%";
    document.querySelector(".fourthLineDiv ").style.top = "42%";
    document.querySelector(".LastLineDiv ").style.top = "50%";
    document.querySelector(".shareTextDiv").style.fontSize = "1rem";

    document.querySelector(".NextText").innerHTML = "下一步";
    document.querySelector(".pickUpNextText").innerHTML = "下一步";

    document.querySelector(".thirdLineDiv").style.fontSize = "1.1rem";

    document.querySelector(".thirdLineDiv").style.display = "none";
    document.querySelector(".giftimgDiv").style.display = "none";
    document.querySelector(".shareTextDiv").style.display = "none";
    document.querySelector(".shareImagesDiv").style.display = "none";
    /* document.querySelector(".thirdLineDiv").firstChild.nodeValue =
      "分享你的分数到 "; */
    // document.querySelector(".fourthLinetxt").innerHTML = "每日高分排行榜";
    document.querySelector(".lastLineTxt").innerHTML = "想不想打破您的记录？";
    document.querySelector(".RetryTxt").innerHTML = "再试一次";

    document.querySelector(".shareTextDiv").innerHTML =
      "分享您的最高分，并有机会赢取惊喜礼物!";
    // document.querySelector(".EXITTxt").innerHTML = "太平门";

    document.querySelector(".fourthLineDiv").firstChild.nodeValue =
      "每日高分排行榜 :";
  }
}
