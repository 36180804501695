import { Global } from "../common/global";
import EventDispatcher from "../common/EventDispatcher";

export default class HtmlHandler {
  constructor(scene) {
    this.scene = scene;
  }
  init() {
    if (Global.gameTry === 1) {
      console.log("HTMLHANDLER");
      this.events = EventDispatcher.getObj();
      this.time = 60;
      this.soundPlaying = true;
      window.country = Global.country;
      window.language = "ENGLISH";

      document.getElementById("startBtn").addEventListener("click", () => {
        if (Global.language != null) this.startClickHandler();
      });

      document.getElementById("ReadyBtn").addEventListener("click", () => {
        this.ReadyBtnClickHandler();
      });

      document.querySelector(".chinaDiv").addEventListener("click", () => {
        this.countrySelectorHandler("CHINA");
      });
      document.querySelector(".JapanDiv").addEventListener("click", () => {
        this.countrySelectorHandler("JAPAN");
      });
      document.querySelector(".KoreaDiv").addEventListener("click", () => {
        this.countrySelectorHandler("KOREA");
      });
      document.querySelector(".usaDiv").addEventListener("click", () => {
        this.countrySelectorHandler("USA");
      });

      document.querySelector(".PlayBtnDiv").addEventListener("click", () => {
        this.playBtnClickHandler();
      });
      document.querySelector(".NextBtnDiv").addEventListener("click", () => {
        this.NextBtnClickHandler();
      });
      document
        .querySelector(".PickUPNextBtnDiv")
        .addEventListener("click", () => {
          this.showScorePage();
        });
      document.getElementById("englishBtn").addEventListener("click", () => {
        this.EnglishBtnClickHandler();
      });
      document.getElementById("chineseBtn").addEventListener("click", () => {
        this.ChineseBtnClickHandler();
      });
      document.querySelector(".fbDiv").addEventListener("click", () => {
        this.FbBtnClickHandler();
      });
      document.querySelector(".weChat").addEventListener("click", () => {
        this.WeChatBtnClickHandler();
      });
      /*  document.querySelector(".ExitBtnDiv").addEventListener("click", () => {
        this.ExitBtnHandler();
      }); */
      document.querySelector(".linkedInDiv").addEventListener("click", () => {
        this.LinkedInBtnClickHandler();
      });
      document
        .querySelector(".LearnMoreBtnDiv")
        .addEventListener("click", () => {
          this.learnMoreBtnClickHandler();
        });
      document.querySelector(".RetryBtnDiv").addEventListener("click", () => {
        this.restartGame();
      });
      document.querySelector(".soundDiv").addEventListener("click", () => {
        this.soundBtnHandler();
      });
      // document.querySelector(".pullPlane").classList.add("blur");
    }
  }
  restartGame() {
    document.querySelector(".scoreValueTxt").innerHTML = 0;
    document.querySelector(".soundDiv").style.display = "none";

    Global.gameTry += 1;
    Global.serverObj.send(
      Global.URL_VALUEupdater,
      null,
      null,
      {
        saveType: "retrybtn",
        uniqID: Global.U_ID,
        game_key: Global.gameKey,
      },
      "POST",
      null,
      false
    );

    document.querySelector(".PickUpCompletedDiv").style.display = "none";
    document.querySelector(".SharePageDiv").style.display = "none";
    document.querySelector(".scorePageDiv").style.display = "none";
    document.querySelector(".BottomSection").style.display = "flex";
    document.querySelector(".topSection").style.display = "flex";
    document.querySelector(".topsec2").style.display = "none";
    this.smCount = 0;
    this.time = 60;
    /* document.querySelector(".SharePageDiv").style.transform =
      "translateY(-300%)"; */
    this.events.dispatchEvent({
      type: "PLAYER_EVENTS",
      message: {
        event_type: "resetPlanes",
        data: {},
      },
    });

    document.querySelector(".timerText").innerHTML = this.time;
    this.speedometerInterval = setInterval(() => {
      document.querySelector(".gameScreenSetUp").style.display = "flex";
      this.smCount += 1;
      if (this.smCount > 30) {
        // this.smCount = Math.floor(Math.random() * (26 - 19 + 1) + 19);
      }
      document.querySelector(
        ".smClass"
      ).src = `https://maerskpickerpilot.com/MaerskAssets/speedometer/sm${this.smCount}.png`;
    }, 2000);

    this.GameTimeInterval = setInterval(() => {
      if (this.time > 0) {
        this.time -= 1;
        if (this.time % 15 === 0) {
          Global.sceneMoveSpeed += 0.0003;
        }
      } else {
        this.showPickUpCompleteFn();
      }
      Global.time = this.time;
      document.querySelector(".timerText").innerHTML = this.time;
    }, 1000);

    setTimeout(() => {
      Global.startTheGame = true;
      Global.sceneMoveSpeed = 0.0015;

      if (Global.willPlayMusic) Global.flightInteriorSound.play();
      Global.takeOffSound.stop();
      Global.finalScore = 0;
      /* document.querySelector(".PickUpCompletedDiv").style.transform =
        "translateY(400%)";
      document.querySelector(".SharePageDiv").style.transform =
        "translateY(500%)";

      document.querySelector(".scorePageDiv").style.transform =
        "translateY(600%)"; */
    }, 1000);
  }
  ExitBtnHandler() {
    Global.serverObj.send(
      Global.URL_VALUEupdater,
      null,
      null,
      {
        saveType: "exitBtn",
        uniqID: Global.U_ID,
        game_key: Global.gameKey,
      },
      "POST",
      null,
      false
    );
    window.close();
  }
  soundBtnHandler() {
    if (this.soundPlaying) {
      document.querySelector(".soundImg").src =
        "https://maerskpickerpilot.com/MaerskAssets/Mute.png";

      Global.takeOffSound.stop();
      Global.bgSound.stop();
      Global.flightInteriorSound.stop();

      Global.willPlayMusic = false;
      this.soundPlaying = false;
    } else {
      document.querySelector(".soundImg").src =
        "https://maerskpickerpilot.com/MaerskAssets/Volume.png";
      Global.bgSound.play();

      this.soundPlaying = true;
      Global.willPlayMusic = true;
    }
  }
  EnglishBtnClickHandler() {
    Global.language = "ENGLISH";
    window.language = "ENGLISH";
    Global.serverObj.send(
      Global.WEBSERVICE,
      null,
      null,
      {
        saveType: "language",
        language: Global.language,
        uniqID: Global.U_ID,
        game_key: Global.gameKey,
      },
      "POST",
      null,
      false
    );

    Global.bgSound.play();

    // document.querySelector(".blurDiv").style.display = "none";
    document.querySelector(".LanguageSelectionDiv").style.transform =
      "translateY(-100%)";

    document.querySelector(".startBtnDiv").style.opacity = "1";

    document.querySelector(".titlePage").style.display = "block";
    setTimeout(() => {
      document.querySelector(".titlePage").style.transform = "translateY(100%)";
    }, 10);
    // document.querySelector(".pullPlane").classList.remove("blur");
  }
  ChineseBtnClickHandler() {
    Global.bgSound.play();
    Global.language = "CHINESE";
    window.language = "CHINESE";
    Global.serverObj.send(
      Global.WEBSERVICE,
      null,
      null,
      {
        saveType: "language",
        language: Global.language,
        uniqID: Global.U_ID,
        game_key: Global.gameKey,
      },
      "POST",
      null,
      false
    );

    // document.querySelector(".blurDiv").style.display = "none";
    /* document.querySelector(".LanguageSelectionDiv").style.transform =
      "translateY(-300%)";

    document.querySelector(".startBtnDiv").style.opacity = "1";
    // document.querySelector(".pullPlane").classList.remove("blur");
    document.querySelector(".titlePage").style.display = "block";
    setTimeout(() => {
      document.querySelector(".titlePage").style.transform = "translateY(100%)";
    }, 10); */
    document.querySelector(".LanguageSelectionDiv").style.transform =
      "translateY(-100%)";

    document.querySelector(".startBtnDiv").style.opacity = "1";

    document.querySelector(".titlePage").style.display = "block";
    setTimeout(() => {
      document.querySelector(".titlePage").style.transform = "translateY(100%)";
    }, 10);

    this.events.dispatchEvent({
      type: "PLAYER_EVENTS",
      message: {
        event_type: "setChinese",
        data: {},
      },
    });
  }
  learnMoreBtnClickHandler() {
    Global.serverObj.send(
      Global.URL_VALUEupdater,
      null,
      null,
      {
        saveType: "learnmorebtn",
        uniqID: Global.U_ID,
        game_key: Global.gameKey,
      },
      "POST",
      null,
      false
    );

    if (Global.language === "ENGLISH") {
      window.open(
        "https://www.maersk.com/semiconductor-logistics-asia-pacific?utm_campaign=APA_MCO_TECH_MULT_TECH-SEMICON-AIR_9853412315048&utm_source=na&utm_medium=direct_awareness&utm_content=GAME_EN",
        "_blank"
      );
    } else {
      window.open(
        "https://www.maersk.com.cn/semiconductor-logistics-asia-pacific?utm_campaign=APA_MCO_TECH_MULT_TECH-SEMICON-AIR_9853412315048&utm_source=na&utm_medium=direct_awareness&utm_content=GAME_CN",
        "_blank"
      );
    }
  }
  FbBtnClickHandler() {
    Global.serverObj.send(
      Global.URL_VALUEupdater,
      null,
      null,
      {
        saveType: "fbbtn",
        uniqID: Global.U_ID,
        game_key: Global.gameKey,
      },
      "POST",
      null,
      false
    );

    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=https://score.maerskpickerpilot.com/share.php?SHAREID=${Global.U_ID}`,
      "_blank"
    );
    /*  window.open(
      "https://www.facebook.com/sharer/sharer.php?u=https://bit.ly/3sixLUB",
      "_blank"
    ); */

    /* let wtext = "MaerskPickerPilot";
    location.href = `https://www.facebook.com/sharer/sharer.php?u=https://newsfeedsmartapp.com/MaerskPickerPilot/index.html&quote=${encodeURIComponent(
      wtext
    )}`; */
    /*  window.open(
      "https://www.facebook.com/sharer.php?u=https://newsfeedsmartapp.com/MaerskPickerPilot/"
    ); */
  }
  LinkedInBtnClickHandler() {
    Global.serverObj.send(
      Global.URL_VALUEupdater,
      null,
      null,
      {
        saveType: "linkedinbtn",
        uniqID: Global.U_ID,
        game_key: Global.gameKey,
      },
      "POST",
      null,
      false
    );
    /* 
    window.open(
      "https://www.linkedin.com/sharing/share-offsite/?url=https://newsfeedsmartapp.com/MaerskPickerPilot/"
    ); */
    window.open(
      "https://www.linkedin.com/sharing/share-offsite/?url=https://bit.ly/3SODwEl"
    );
    /*  window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=https://score.maerskpickerpilot.com/share.php?SHAREID=${Global.U_ID}`
    ); */

    /* var url = encodeURIComponent(
      "https://newsfeedsmartapp.com/MaerskPickerPilot/"
    );

    var summary = encodeURIComponent("Check out this amazing article!");

    var title = encodeURIComponent("Amazing Article");

    var source = encodeURIComponent("Example");

    var linkedInUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}&summary=${summary}&source=${source}`;

    window.open(linkedInUrl, "_blank"); */
  }
  WeChatBtnClickHandler() {
    Global.serverObj.send(
      Global.URL_VALUEupdater,
      null,
      null,
      {
        saveType: "weChatBtn",
        uniqID: Global.U_ID,
        game_key: Global.gameKey,
      },
      "POST",
      null,
      false
    );

    /*  window.open(
      "weixin://dl/businessWebview/link?url=https%3A%2F%2Fnewsfeedsmartapp.com%2FMaerskPickerPilot%2F"
    ); */
    window.open(
      "weixin://dl/businessWebview/link?url=https%3A%2F%2Fbit.ly%2F47r6le8"
    );
  }

  startClickHandler() {
    Global.serverObj.send(
      Global.URL_VALUEupdater,
      null,
      null,
      {
        saveType: "startbtn",
        uniqID: Global.U_ID,
        game_key: Global.gameKey,
      },
      "POST",
      null,
      false
    );

    document.querySelector(".soundDiv").style.display = "block";
    document.querySelector(".titlePage").style.transform = "translateY(0%)";
    this.goToInstructionPage();
    /*  document.querySelector(".videoPage").style.display = "block";
    setTimeout(() => {
      document.querySelector(".videoPage").style.transform =
        "translateY(-100%)";
    }, 1); */
    // this.progressBarHandler();
  }

  progressBarHandler() {
    let widthVal = 0;
    this.barInterVal = setInterval(() => {
      if (widthVal < 100) {
        widthVal += 1;
        if (widthVal == 50)
          if (Global.language == "CHINESE") {
            document.querySelector(".progressBarText").innerHTML = "准备取货";
          } else {
            document.querySelector(".progressBarText").innerHTML =
              "READY FOR" + "<br>" + "PICKUP";
          }
      } else {
        this.VideoEnd();
        clearInterval(this.barInterVal);
      }
      document.getElementById("myBar").style.width = `${widthVal}%`;
    }, 50);
  }
  goToInstructionPage() {
    console.log("goToInstructionPage");
    // document.querySelector(".videoPage").style.display = "none";
    // document.querySelector(".videoPage").style.transform = "translateY(-200%)";
    document.querySelector(".InstructionPage").style.display = "block";
    setTimeout(() => {
      document.querySelector(".InstructionPage").style.transform =
        "translateY(0%)";
    }, 10);

    let instVal = 0;
    this.instructionInterval = setInterval(() => {
      instVal += 1;
      if (instVal > 28) instVal = 1;
      document.querySelector(
        ".instLocationIMG"
      ).src = `https://maerskpickerpilot.com/MaerskAssets/location/Location${instVal}.png`;
      document.querySelector(
        ".instClockIMG"
      ).src = `https://maerskpickerpilot.com/MaerskAssets/clock/Stop${instVal}.png`;
      document.querySelector(
        ".instSteeringIMG"
      ).src = `https://maerskpickerpilot.com/MaerskAssets/steering/Steering${instVal}.png`;
      document.querySelector(
        ".instChipIMG"
      ).src = `https://maerskpickerpilot.com/MaerskAssets/chip/Chip${instVal}.png`;
      if (instVal < 25) {
        document.querySelector(
          ".instConeIMG"
        ).src = `https://maerskpickerpilot.com/MaerskAssets/cone/Cone_${instVal}.png`;
      }
    }, 100);
  }

  ReadyBtnClickHandler() {
    Global.serverObj.send(
      Global.URL_VALUEupdater,
      null,
      null,
      {
        saveType: "readytogobtn",
        uniqID: Global.U_ID,
        game_key: Global.gameKey,
      },
      "POST",
      null,
      false
    );

    clearInterval(this.instructionInterval);
    // document.querySelector(".InstructionPage").style.display = "none";
    document.querySelector(".InstructionPage").style.transform =
      "translateY(-100%)";
    document.querySelector(".countrySelectorDiv").style.display = "block";
    setTimeout(() => {
      document.querySelector(".countrySelectorDiv").style.transform =
        "translateY(-100%)";
    }, 10);
  }

  countrySelectorHandler(countryName) {
    Global.country = countryName;
    if (Global.language == "CHINESE") {
      document.querySelector(".CHINAimg").src =
        "https://maerskpickerpilot.com/MaerskAssets/CHINESE_CHINA.png";
      document.querySelector(".JAPANimg").src =
        "https://maerskpickerpilot.com/MaerskAssets/CHINESE_JAPAN.png";
      document.querySelector(".KOREAimg").src =
        "https://maerskpickerpilot.com/MaerskAssets/CHINESE_KOREA.png";
      document.querySelector(".USAimg").src =
        "https://maerskpickerpilot.com/MaerskAssets/CHINESE_USA.png";

      document.querySelector(
        `.${countryName}img`
      ).src = `https://maerskpickerpilot.com/MaerskAssets/CHINESE_${countryName}_select.png`;
    } else {
      //  console.log(document.querySelector(`.${countryName}img`), "countryName");

      document.querySelector(".CHINAimg").src =
        "https://maerskpickerpilot.com/MaerskAssets/CHINA.png";
      document.querySelector(".JAPANimg").src =
        "https://maerskpickerpilot.com/MaerskAssets/JAPAN.png";
      document.querySelector(".KOREAimg").src =
        "https://maerskpickerpilot.com/MaerskAssets/KOREA.png";
      document.querySelector(".USAimg").src =
        "https://maerskpickerpilot.com/MaerskAssets/USA.png";

      document.querySelector(
        `.${countryName}img`
      ).src = `https://maerskpickerpilot.com/MaerskAssets/${countryName}_select.png`;
    }
    window.country = Global.country;
  }
  playBtnClickHandler() {
    Global.serverObj.send(
      Global.WEBSERVICE,
      null,
      null,
      {
        saveType: "selectcountry",
        countryname: Global.country,
        uniqID: Global.U_ID,
        game_key: Global.gameKey,
      },
      "POST",
      null,
      false
    );

    Global.serverObj.send(
      Global.URL_VALUEupdater,
      null,
      null,
      {
        saveType: "playbtn",
        uniqID: Global.U_ID,
        game_key: Global.gameKey,
      },
      "POST",
      null,
      false
    );

    console.log(Global.country, " Global.country");

    document.querySelector(".soundDiv").style.display = "none";

    document.querySelector(".countrySelectorDiv").style.transform =
      "translateY(-200%)";
    document.querySelector(".videoPage").style.display = "block";
    setTimeout(() => {
      document.querySelector(".videoPage").style.transform =
        "translateY(-200%)";
    }, 10);
    this.progressBarHandler();
  }

  VideoEnd() {
    document.querySelector(".videoPage").style.transform = "translateY(-300%)";
    document.querySelector(".gameScreenSetUp").style.display = "flex";
    // console.log("PLAYYYY");

    this.events.dispatchEvent({
      type: "PLAYER_EVENTS",
      message: {
        event_type: "add_BasketBall_set",
        data: {},
      },
    });
    this.events.dispatchEvent({
      type: "PLAYER_EVENTS",
      message: {
        event_type: "add_Plane",
        data: {},
      },
    });
    this.events.dispatchEvent({
      type: "PLAYER_EVENTS",
      message: {
        event_type: "add_Items",
        data: {},
      },
    });
    /*  setTimeout(() => {
      Global.startTheGame = true;
      Global.flightInteriorSound.play();
    }, 1000); */
    setTimeout(() => {
      document.querySelector(".initTimeText").innerHTML = "2";
      setTimeout(() => {
        document.querySelector(".initTimeText").innerHTML = "1";
        setTimeout(() => {
          document.querySelector(".initTimeText").innerHTML = "START";
          setTimeout(() => {
            document.querySelector(".IntialTimerDiv").style.display = "none";
            Global.startTheGame = true;
            if (Global.willPlayMusic) Global.flightInteriorSound.play();

            this.smCount = 0;
            this.smLow = 10;
            this.smHigh = 15;
            this.speedometerInterval = setInterval(() => {
              this.smCount += 1;
              /*  if (this.smCount > 15) {
                this.smCount = Math.floor(
                  Math.random() * (this.smHigh - this.smLow + 1) + this.smLow
                );
                console.log(this.smCount, "smms");
              } */
              document.querySelector(
                ".smClass"
              ).src = `https://maerskpickerpilot.com/MaerskAssets/speedometer/sm${this.smCount}.png`;
            }, 2000);

            this.GameTimeInterval = setInterval(() => {
              if (this.time > 0) {
                this.time -= 1;
                if (this.time % 15 === 0) {
                  Global.sceneMoveSpeed += 0.0003;
                  /* if (this.time == 45) {
                    this.smLow = 16;
                    this.smHigh = 20;
                    console.log(this.smLow, " this.smLow");
                  } else if (this.time == 30) {
                    this.smLow = 21;
                    this.smHigh = 25;
                  } else if (this.time == 15) {
                    this.smLow = 26;
                    this.smHigh = 30;
                  } */
                }
              } else {
                Global.flightInteriorSound.stop();
                this.showPickUpCompleteFn();
              }
              Global.time = this.time;
              document.querySelector(".timerText").innerHTML = this.time;
            }, 1000);
          }, 1000);
        }, 1000);
      }, 1000);
    }, 1000);
  }
  showPickUpCompleteFn() {
    Global.serverObj.send(
      Global.WEBSERVICE,
      null,
      null,
      {
        saveType: "saveScore",
        score: Global.finalScore,
        gameTry: Global.gameTry,
        uniqID: Global.U_ID,
        game_key: Global.gameKey,
      },
      "POST",
      null,
      false
    );

    Global.serverObj.send(
      Global.WEBSERVICE,
      null,
      null,
      {
        saveType: "generateThumbnail",
        uniqID: Global.U_ID,
        score: Global.finalScore,
      },
      "POST",
      null,
      false
    );

    Global.startTheGame = false;
    clearInterval(this.GameTimeInterval);
    clearInterval(this.speedometerInterval);

    document.querySelector(".soundDiv").style.display = "block";
    // document.querySelector(".speedMeter").style.opacity = "0";
    document.querySelector(".topSection").style.display = "none";
    document.querySelector(".topsec2").style.display = "flex";
    this.events.dispatchEvent({
      type: "PLAYER_EVENTS",
      message: {
        event_type: "add_takeoffPlane",
        data: {},
      },
    });
    document.querySelector(".BottomSection").style.display = "none";
    document.querySelector(".PickUpCompletedDiv").style.display = "flex";
    /* setTimeout(() => {
      document.querySelector(".PickUpCompletedDiv").style.transform =
        "translateY(-30%)";
    }, 10); */

    /* setTimeout(() => {
      document.querySelector(".PickUpCompletedDiv").style.display = "none";
      this.showScorePage();
    }, 4000); */
  }
  showScorePage() {
    Global.serverObj.send(
      Global.URL_VALUEupdater,
      null,
      null,
      {
        saveType: "pickupNext",
        uniqID: Global.U_ID,
        game_key: Global.gameKey,
      },
      "POST",
      null,
      false
    );

    document.querySelector(".PickUpCompletedDiv").style.display = "none";
    document.querySelector(".scoreScreenValText").innerHTML = Global.finalScore;
    document.querySelector(".scorePageDiv").style.display = "flex";
    /* setTimeout(() => {
      document.querySelector(".scorePageDiv").style.transform =
        "translateY(-50%)";
    }, 10); */
  }
  NextBtnClickHandler() {
    Global.serverObj.send(
      Global.URL_VALUEupdater,
      null,
      null,
      {
        saveType: "nextbtn",
        uniqID: Global.U_ID,
        game_key: Global.gameKey,
      },
      "POST",
      null,
      false
    );

    Global.serverObj.send(
      Global.WEBSERVICE,
      (data) => {
        let newData = JSON.parse(data);
        Global.topScoreOfDay = newData.highest_score;
        // console.log(Global.topScoreOfDay, "data");
        document.querySelector(".TopScoreValueTxt").innerHTML =
          " " + Global.topScoreOfDay;
      },
      null,
      {
        saveType: "Leaderboard",
      },
      "POST",
      null,
      false
    );

    document.querySelector(".sharePageTopScore").innerHTML = Global.finalScore;

    document.querySelector(".scorePageDiv").style.display = "none";
    document.querySelector(".SharePageDiv").style.display = "flex";

    /* document.querySelector(".scorePageDiv").style.transform =
      "translateY(-350%)";
    document.querySelector(".SharePageDiv").style.display = "flex";
    setTimeout(() => {
      document.querySelector(".SharePageDiv").style.transform =
        "translateY(-110%)";
    }, 10); */
  }
}
